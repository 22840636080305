import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { differenceInSeconds, startOfToday } from 'date-fns';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import Cookies from 'js-cookie';

import { useIntl } from 'react-intl';

import { ImageBackground, CountdownOverlay, Overlay } from './webinar/index';

import { AuthContext, LoaderContext } from './../contexts';
import { analyticsTrack } from './../utils/analytics';
import { checkLanguage } from './../utils/utility';
import { useAnalyticsTrack } from '../utils/analyticsTrackHelper';
//import { useChatModerator } from '../utils/moderatorHelper';

import { aws, useSessions } from './../utils';
const { standardAPI } = aws;

const REACT_APP_BR_BACK_REDIRECT = process.env.REACT_APP_BR_BACK_REDIRECT;

const Webinar = () => {
  //Contexts
  const { setLoaderActive } = useContext(LoaderContext);
  const { user } = useContext(AuthContext);

  //Loader Function
  const showLoader = () => setLoaderActive(true);
  const hideLoader = () => setLoaderActive(false);

  //Hooks
  const { slugId } = useParams();
  const sessionHelper = useSessions({ showLoader, hideLoader });
  const intl = useIntl();
  const analyticsTrackHelper = useAnalyticsTrack();
  // const pubnabService = useChatModerator({});

  //State
  const [start, setStart] = useState();
  const [userIsAllowed, setUserIsAllowed] = useState(false);
  const [session, setSession] = useState();
  const [secondRemainingStart, setSecondRemainingStart] = useState();
  const [analyticsFeature, setAnalyticsFeature] = useState();
  //const [clientWithoutAuth, setClientWithoutAuth] = useState();
  // const [hasBeenBanned, setHasBeenBanned] = useState(false);
  const [client, setClient] = useState();
  const [clientData, setClientData] = useState({
    uuid: null,
    msgChannel: null,
  });

  //Effects
  useEffect(() => {
    let lang;
    if (location.search) {
      lang = new URLSearchParams(location.search).get('lang');
    }

    if (
      (user && intl.locale && !lang) ||
      (intl.locale && lang === intl.locale)
    ) {
      fetchData();
    }
  }, [intl.locale, user]);

  useEffect(() => {
    if (session?.id && user?.id && session.isPrivate) {
      // First of all, handle Private Sessions
      const userEmail = user?.Email;
      const whiteList =
        session.invited && session.invited?.[0]?.value
          ? session.invited[0].value.split(';').filter((s) => !!s)
          : [];
      if (whiteList.length && !whiteList.find((e) => e === userEmail)) {
        // Send Back Home
        window.location.href = REACT_APP_BR_BACK_REDIRECT;
      } else {
        setUserIsAllowed(true);
      }
    }
  }, [session, user]);

  useEffect(() => {
    if (session?.id && user?.id && (userIsAllowed || !session.isPrivate)) {
      if (analyticsFeature) {
        const gqlFeature = {
          ...analyticsFeature,
          sessionId: session.id,
          sessionSlug: session.slug,
        };
        createCustomAnalyticsTrack(gqlFeature);
      }
      if (!client) {
        initChatClient();
      }
    }
  }, [session, user, analyticsFeature, userIsAllowed]);

  useEffect(() => {
    if (user && session?.id && (userIsAllowed || !session.isPrivate)) {
      const userId = user.id;
      const language = checkLanguage();

      const _analyticsFeatures = {
        pageType: `WEBINAR_${language.toUpperCase()}`,
        language,
        userId,
        loginStatus: 'logged',
        EVENT_LANGUAGE: language?.toUpperCase?.(),
        EVENT_ID: session?.id,
        EVENT_NAME: session?.name,
        EVENT_TYPE: 'Event_Live',
      };
      setAnalyticsFeature(_analyticsFeatures);

      analyticsTrack(_analyticsFeatures);
    }
  }, [user, session, userIsAllowed]);

  const createCustomAnalyticsTrack = async (input) => {
    try {
      await analyticsTrackHelper.create(input);
    } catch (e) {
      console.error(e);
    }
  };

  // useEffect(() => {
  //   if (clientWithoutAuth && clientData) {
  //     setClientAuth();
  //   }
  // }, [clientWithoutAuth, clientData]);
  //Functions
  const counterTotalTime = differenceInSeconds(start, startOfToday());

  const fetchData = async () => {
    await getAgendaSession();
  };

  const initChatClient = async () => {
    const newClientData = {
      uuid: user.id,
      msgChannel: session.id,
    };
    setClientData(newClientData);

    const PUBNUB_PUBLISH_KEY = process.env.REACT_APP_PUBNUB_PUBLISH_KEY;
    const PUBNUB_SUBSCRIBE_KEY = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;

    const pubNubConfig = {
      publishKey: PUBNUB_PUBLISH_KEY,
      subscribeKey: PUBNUB_SUBSCRIBE_KEY,
      uuid: newClientData.uuid,
    };
    const _clientWithoutAuth = new PubNub(pubNubConfig);
    setClient(_clientWithoutAuth);
  };
  //TODO: cambiare quando ritorna il moderation

  // const setClientAuth = async () => {
  //   const loginResponse = await pubnabService.login(clientData);
  //   if (loginResponse.message.error) {
  //     setHasBeenBanned(true);
  //   }

  //   clientWithoutAuth.setAuthKey(loginResponse.authKey);
  //   setClient(clientWithoutAuth);
  // };

  //TODO: cambiare quando ritorna il moderation

  const safeNum = (value) => (isNaN(Number(value)) ? 0 : Number(value));

  const formatDrupalDate = (ts) => {
    return new Date(safeNum(ts) * 1000);
  };

  const getAgendaSession = async () => {
    try {
      const res = await sessionHelper.get(slugId);
      const sessionId = res.externalId;

      const { accessToken } = JSON.parse(Cookies.get('sdm-auth') || '{}');
      const input = {
        body: {
          accessToken,
        },
      };

      const apiName = 'virtualEventInterface';
      const path = `/external-session/${sessionId}?language=${intl.locale}`;
      const externalSession = await standardAPI.post(apiName, path, input);
      const speakers = Object.values(externalSession?.interventi || {}).flatMap(
        (intervention) => {
          return Object.values(intervention?.speakers || {}).map((speaker) => ({
            id: `${intervention?.id}_${speaker?.id_paragraph}`,
            start: formatDrupalDate(intervention?.start),
            end: formatDrupalDate(intervention?.end),
            description: intervention?.title,
            givenName: speaker?.name,
            familyName: speaker?.surname,
            image: speaker?.image,
          }));
        }
      );

      const nextSession = {
        ...externalSession,
        start: formatDrupalDate(externalSession.start),
        end: formatDrupalDate(externalSession.end),
        speakers,
      };

      setSession(nextSession);

      // initialize page
      setStart(new Date(nextSession.start));
      setSecondRemainingStart(
        differenceInSeconds(
          new Date(nextSession.start),
          new Date(nextSession.serverTime)
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <div style={{ height: '100vh', width: '100vw' }}>
        <div
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'black',
          }}
        >
          <div
            style={{
              maxHeight: '100vh',
              width: '100vw',
              height: 'calc(100vw / 16 * 9)',
              maxWidth: '177vh',
              overflow: 'hidden',
            }}
          >
            {secondRemainingStart != null && secondRemainingStart <= 0 ? (
              <iframe
                src={session?.urlStreaming}
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                width="100%"
                height="100%"
                scrolling="no"
              ></iframe>
            ) : null}
          </div>
        </div>

        {secondRemainingStart != null && secondRemainingStart > 0 ? (
          <ImageBackground
            src={session?.coverImage}
            showShadow
            overlayOpacity={secondRemainingStart > 0 ? 0.55 : 0.8}
          />
        ) : null}
      </div>

      {secondRemainingStart != null && secondRemainingStart <= 0 ? (
        <>
          {client && (
            <PubNubProvider client={client}>
              <Overlay
                msgChannel={clientData?.msgChannel}
                uuid={clientData?.uuid}
                session={session}
                hasBeenBanned={false}
              />
            </PubNubProvider>
            //TODO: cambaiare quando ritorna il moderation
          )}
        </>
      ) : null}
      {secondRemainingStart != null && secondRemainingStart > 0 ? (
        <CountdownOverlay
          totalTime={counterTotalTime}
          initialRemainingTime={secondRemainingStart}
          onComplete={() => setSecondRemainingStart(-1)}
        />
      ) : null}
    </div>
  );
};

export { Webinar };
