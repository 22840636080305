import { Amplify, API, graphqlOperation } from 'aws-amplify';

import awsmobile from './../aws-exports';
import Cookies from 'js-cookie';

Amplify.configure(awsmobile);
API.configure(awsmobile);

const customPost = (API, { query, variables, limitless }) => {
  const { accessToken } = JSON.parse(Cookies.get('sdm-auth') || '{}');

  return API.post('authendpoint', '/query', {
    body: { query, variables, accessToken, limitless },
  });
};

const lambdaPost = (API, { apiName, path, body }) => {
  return API.post(apiName, path, {
    body,
  });
};

export default {
  API: {
    lambdaPost: (apiName, path, body) =>
      lambdaPost(API, { apiName, path, body }),
    post: (query, variables, limitless) =>
      customPost(API, { query, variables, limitless }),
  },
  standardAPI: API,
  graphql: {
    graphqlOperation,
  },
};
