import aws from './aws';
const { API } = aws;

const createAnalyticsTrackQuery = /* GraphQL */ `
  mutation CreateAnalyticsTrack(
    $input: CreateAnalyticsTrackInput!
    $condition: ModelAnalyticsTrackConditionInput
  ) {
    createAnalyticsTrack(input: $input, condition: $condition) {
      id
      pageType
      language
      userId
      loginStatus
      createdAt
      updatedAt
    }
  }
`;

export const useAnalyticsTrack = () => {
  const create = (input) =>
    new Promise((resolve, reject) => {
      API.post(createAnalyticsTrackQuery, { input })
        .then((response) => {
          resolve(response.data.createAnalyticsTrack);
        })
        .catch((e) => {
          console.error('create-reaction', e);
          reject();
        });
    });
  return { create };
};
